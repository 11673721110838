import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { navigate } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import Stage from '@components/stage'
import Headline from '@objects/headline'
import Tabs from '@objects/tabs'
import FaqAccordion from '@objects/faqAccordion'

export const frontmatter = {
  intl: {
    slug: {
      en: 'glossary',
    },
  },
}

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(8),
  },
  accordionList: {
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 23),
    },
  },
}))

function GlossarPage({ pageContext, modules, data }) {
  const classes = useStyles()
  const location = useLocation()

  const glossaryList = get(data, 'allContentfulGlossarEntry.nodes')
    .filter((entry) => entry.synonyms)
    .map((entry) => {
      return {
        id: entry.synonyms[0].replace(/\s+/g, ''),
        question: entry.title,
        answer: entry.description?.json?.content,
        glossaryExcludes: entry.synonyms,
        tooltipOnly: entry.tooltipOnly,
      }
    })

  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const glossarySections = letters.match(/[A-Za-z]/g).map((letter) => {
    return {
      letter: letter,
      entries: glossaryList.filter(
        (entry) =>
          entry.question.match(
            new RegExp(`^[${letter}${letter.toLowerCase()}]`, 'i')
          ) && !entry.tooltipOnly
      ),
    }
  })

  const [active, setActive] = useState(
    glossarySections.findIndex((s) => s.entries.length > 0)
  )

  // open tab from url hash
  useEffect(() => {
    const query = queryString.parse(location.hash).glossaryitem
    if (query) {
      const index = glossarySections.findIndex(
        (s) => s.letter.toLowerCase() === query.charAt(0).toLowerCase()
      )
      if (index && glossarySections[index].entries.length > 0) {
        setActive(index)
      }
    }
  }, [location.hash])

  function renderPanels() {
    return glossarySections.map((section, i) => {
      return (
        <FaqAccordion
          key={i}
          className={classes.accordionList}
          headline={section.letter}
          id={section.letter.replace(/\s+/g, '')}
          faqs={section.entries}
          type={'glossary'}
          headlineLevel={3}
        />
      )
    })
  }

  function changeTab() {
    navigate(location.pathname, {
      replace: true,
      state: {
        preventScroll: true,
      },
    })
  }

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page.title}
        ariaLabel={pageContext.page.title}
      />
      <Container width="md" ariaLabel="arialabel.glossary">
        <Headline className={classes.headline} level={2}>
          <FormattedMessage id="glossary.headline" />
        </Headline>
        <Tabs
          tabHeads={glossarySections.map((section) => {
            return {
              label: section.letter,
              disabled: section.entries.length > 0 ? false : true,
              onClick: changeTab,
            }
          })}
          type="simple"
          active={active}
        >
          {renderPanels()}
        </Tabs>
      </Container>
    </>
  )
}

GlossarPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default GlossarPage

export const pageQuery = graphql`
  query GlossaryPageQuery($locale: String!) {
    allContentfulGlossarEntry(filter: { node_locale: { eq: $locale } }) {
      nodes {
        description {
          json
        }
        title
        synonyms
        tooltipMessage
        tooltipOnly
      }
    }
  }
`
